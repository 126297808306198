<template>
  <esmp-table-wrapper
    class="priorities"
    title="Управление приоритетами"
    :is-column-settings-button-showed="false"
    :is-allow-full-page="false"
  >
    <esmp-table
      :columns="columns"
      :rows="rows"
      :loading="loading"
      stripe
    >
      <template #cell-translatedName="{ tr }">
        <esmp-editable-text :value="tr.translatedName" @input="(v) => editValue(v, tr)" />
      </template>
      <template #cell-color="{ tr }">
        <esmp-color-picker
          :value="tr.color"
          without-text-input
          @input="(c) => editColor(c, tr)"
        />
      </template>
    </esmp-table>
  </esmp-table-wrapper>
</template>

<script>
export default {
  name: 'Priorities',
  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: 'Название на портале',
          key: 'translatedName',
        },
        {
          title: 'Название(En)',
          key: 'name',
        },
        {
          title: 'Название(Ru)',
          key: 'msTranslatedName',
        },
        {
          title: 'Цвет',
          key: 'color',
        },
      ],
      rows: [],
    };
  },
  methods: {
    getPriorities() {
      this.loading = true;
      this.$API.admin.getPriorities()
        .then(({ data }) => {
          this.rows = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePriority(priority) {
      this.loading = true;
      this.$API.admin.updatePriority(priority.id, priority)
        .finally(() => {
          this.loading = false;
        });
    },
    editValue(v, tr) {
      Object.assign(tr, { translatedName: v });
      this.updatePriority(tr);
    },
    editColor(v, tr) {
      Object.assign(tr, { color: v });
      this.updatePriority(tr);
    },
  },
  created() {
    this.getPriorities();
  },
};
</script>
<style lang="scss">
.input-color {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  &__no-value {
    opacity: 0.05;
  }
}
/* -webkit */
.input-color::-webkit-color-swatch-wrapper {
  padding: 0;
}

.input-color::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* firefox */
.input-color::-moz-focus-inner {
  border: none;
  padding: 0;
  border-radius: 50%;
}

.input-color::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}
.priorities .esmp-table__thead-th:last-child {
  width: 56px;
}
.priorities .esmp-table__tbody-td:last-child {
  text-align: center;
}
</style>
